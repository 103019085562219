<template>
	<div class="notificationpanel d-flex flex-column max-height-100">
		<div class="px-3 pt-3">
			<!-- Header -->
			<div class="d-flex align-start mb-2">
				<div class="d-flex flex-column">
					<div class="notificationttype mb-2">{{ elementType }}</div>
					<div>
						<span class="notificationtid">{{ notificationFile }}</span>
						<!-- <span class="fs-sm">{{ ' - ' + this.feature.data['rule_name'] }}</span> -->
					</div>
				</div>

				<v-btn @click="closePanel" depressed icon height="32" width="32" class="closebtn ml-auto" :title="closeTitleBtn">
					<v-icon size="0.9rem">far fa-times</v-icon>
				</v-btn>
			</div>

			<!-- Subtype -->
			<div class="d-flex align-center ml-1">
				<svg-icon :icon="notificationIcon" :width="22"></svg-icon>
				<div class="notificationsubtypetext pl-5">{{ this.feature.data['datasourcetype_name'] }} - {{ this.feature.data['status'] }}</div>
			</div>
			<!-- <v-divider class="mt-2" style="height: 1px; background: rgba(0, 0, 0, 0.05)"></v-divider> -->
			<!-- Date info -->
			<v-list lines="one" density="compact" class="date-container d-flex justify-start pa-0 ml-1 mt-2">
				<v-icon size="12" class="fw-500 pl-1 pr-2">fak fa-solid-hour</v-icon
				><span class="date-text fw-regular fs-sm ma-0 px-0 py-1">
					{{ getElasticSearchDate() }}
				</span>
			</v-list>
			<!-- <v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)"></v-divider> -->

			<!-- Tabs header -->
			<v-tabs v-model="tabActive" ref="tabnotifications" color="blue" background-color="rgba(0, 0, 0, 0)" class="mt-3" show-arrows>
				<v-tab ref="values-tab" href="#values-tab">{{ $t('panels.notification.values') }}</v-tab>
				<v-tab ref="information-tab" href="#information-tab">{{ $t('panels.notification.information') }}</v-tab>
			</v-tabs>
		</div>

		<!-- Tabs -->
		<v-tabs-items v-model="tabActive" style="overflow-y: auto; background: rgba(0, 0, 0, 0) !important">
			<v-tab-item value="values-tab" class="notificationtab">
				<notification-tab-values :parameters="elasticParameters" :isAverage="isAverage" />
			</v-tab-item>

			<v-tab-item value="information-tab" class="notificationtab">
				<notification-tab-information :feature="feature" :elasticData="elasticData" />
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import SvgIcon from '@/components/map/icon/SvgIcon';
import NotificationTabInformation from './NotificationTabInformation';
import NotificationTabValues from './NotificationTabValues';

import PanelMixin from '@/mixins/PanelMixin';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';

import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import constants from '@/utils/constants';
import { getIconsTypeIcon, formatTimestamp } from '@/api/common';
export default {
	name: 'NotificationPanel',

	data() {
		return {
			panelType: NOTIFICATIONS_LAYER,
			tabActive: 'values-tab',
			feature: {},
			elasticParameters: [],
			attributes: [],
			elasticData: {},
			isAverage: false
		};
	},

	mixins: [PanelMixin, QueriesSQLMixin],

	components: {
		SvgIcon,
		NotificationTabValues,
		NotificationTabInformation
	},

	created() {
		//this.getNotificationData();
		this.feature = this.options;
		console.log('options notification', this.options);
		console.log('==== Detail Notification ====', this.options, this.formatDetailNotification(this.options));
		this.formatDetailNotification(this.options);

		this.$puiEvents.$on('map:updated', this.getNotificationData);
	},

	destroyed() {
		this.$puiEvents.$off('map:updated', this.getNotificationData);
	},

	computed: {
		notificationFile() {
			return this.feature.data['station_code'] ?? 'Codigo id';
		},

		elementType() {
			return this.$t('panels.configpannel.notification') /* 'Notificacion' */;
		},

		notificationIcon() {
			return (
				this.feature.data['icon_route'] ??
				getIconsTypeIcon(constants.NOTIFICATIONS_ICONS_TYPE, constants.NOTIFICATIONS_TAB_ACRONYM, this.$store.getters.getIcons).route
			);
		}
	},
	methods: {
		formatDetailNotification(detailNotification) {
			console.log('formatDetailNotification', detailNotification, this.$store.getters.getParameters);
			var details = [];
			if (detailNotification.data['is_average'] == 0) {
				detailNotification.elastic.then((msg) => {
					let filteredParameters = this.$store.getters.getParameters.filter(
						(parameter) => parameter.pmdatasourcetypeid == detailNotification.data['pm_datasourcetype_id']
					);
					let obj = {};
					console.log('Reading promise', msg, filteredParameters);
					this.elasticData = obj = msg;

					filteredParameters.forEach((parameter) => {
						if (Object.hasOwn(obj, parameter.elasticacronym)) {
							details.push({
								name: parameter.name,
								value:
									parseInt(obj[parameter.elasticacronym]) == obj[parameter.elasticacronym]
										? parseInt(obj[parameter.elasticacronym])
										: parseFloat(obj[parameter.elasticacronym]).toFixed(2),
								unit: parameter.symbol,
								id: parameter.pmparameterid,
								isaverage: detailNotification.data['is_average'],
								rulename: detailNotification.data['rule_acronym']
							});
						}
					});

					console.log('# Details: ', details);
					this.elasticParameters = details;
					return details;
				});
			} else {
				let filteredParameters = this.$store.getters.getParameters.filter(
					(parameter) => parameter.pmdatasourcetypeid == detailNotification.data['pm_datasourcetype_id']
				);
				let parameter = filteredParameters.find((parameter) => parameter.acronym == detailNotification.data['parameter_acronym']);
				if (parameter) {
					details.push({
						name: parameter.name,
						value:
							parseInt(detailNotification.data['parameter_value']) == detailNotification.data['parameter_value']
								? parseInt(detailNotification.data['parameter_value'])
								: parseFloat(detailNotification.data['parameter_value']).toFixed(2),
						unit: parameter.symbol,
						id: parameter.pmparameterid,
						isaverage: detailNotification.data['is_average'],
						rulename: detailNotification.data['rule_acronym'],
						population: detailNotification.data['population'],
						interval: this.getInterval(detailNotification.data['pm_rules_id'], detailNotification.data['start_date'])
					});
				}
				console.log('# Details: ', details);
				this.elasticParameters = details;
				this.isAverage = true;
				return details;
			}
		},
		getInterval(rule_id, start_date) {
			let rule = this.$store.getters.getRules.find((rule) => rule.pmrulesid == rule_id);
			if (rule) {
				return [formatTimestamp(parseInt(start_date - rule.windowseconds * 1000)), formatTimestamp(parseInt(start_date))];
			}
			return null;
		},
		getElasticSearchDate() {
			console.log('feature notification', this.feature);
			const timestamp = parseInt(this.feature.data['start_date']);
			console.log(timestamp);

			return formatTimestamp(timestamp, this.userProperties);
		}
	},
	watch: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.notificationpanel {
	& .notificationttype {
		font-size: 0.9rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--medium);
	}

	& .closebtn {
		background-color: var(--low);

		& i {
			color: var(--primary);
		}

		&:hover {
			& i {
				color: var(--mapfre-accent);
			}
		}
	}

	& .notificationtid {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
	}

	& >>> .v-tabs__slider.accent {
		background-color: #00ff00 !important;
		border-color: #00ff00 !important;
	}

	& >>> .v-tabs__item.v-tabs__item {
		font-size: 0.9rem;
		color: var(--medium) !important;
		&--active {
			color: #00ff00 !important;
		}
	}

	& >>> .v-slide-group__wrapper {
		border-bottom: 1px solid #ccc !important;
	}

	.date-container {
		background-color: var(--fondo-1);
		& > * {
			color: var(--gris-300);
		}
	}
}
</style>
