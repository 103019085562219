<template>
	<div class="d-flex align-center" style="gap: 0.6rem">
		<v-progress-linear
			class="status-linear my-1"
			:value="value"
			:color="color"
			height="5"
			background-color="rgba(180, 180, 180, .3)"
			style="align-self: flex-end"
		/>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			required: true
		},

		color: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="postcss">
.status-linear {
	border-radius: 2px;
}
.line-chart {
	&__percentage {
		font-size: 1.25rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.11;
		letter-spacing: normal;
		color: #707276;
		white-space: nowrap;
		margin-right: 15px;
	}

	&__symbol {
		font-size: 2.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.25;
		letter-spacing: normal;
		color: #707276;
	}
}
</style>
