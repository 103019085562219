<template>
	<div class="notificationvaluestatus d-flex justify-center align-center">
		<div class="point" :style="'background: ' + color"></div>
		<div v-for="(item, index) in items" :key="index">
			<div v-for="(slot, index) in item.slot" :key="index" :class="slot"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'notificationvaluestatus',

	props: {
		color: {
			type: String,
			required: true
		}
	},

	components: {},

	computed: {
		items() {
			return [
				{
					id: 'status',
					title: this.$t('status.status'),
					items: [
						{
							text: this.$t('status.ok'),
							slot: ['point ok']
						}
					]
				}
			];
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/eiffel-variables.pcss';
.notificationvaluestatus {
	& .point {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		font-size: 10px;
	}
}
</style>
